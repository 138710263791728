import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import document from "../../images/dasboardicon/Document_White.svg";
import frame from "../../images/dasboardicon/Framenew.svg";
import Modal from "react-bootstrap/Modal";
import orgIcon from "../../images/organizations.svg";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./LeftSidebar.css";
import arrowCollapse from "../../images/m-arrow-left.svg";
import homeIconFill from "../../images/icon1-fill.svg";
import patientsIconFill from "../../images/icon2-fill.svg";
import mgmtPlanIconFill from "../../images/icon3-fill.svg";
import opNotesIconFill from "../../images/icon4-fill.svg";
import settingsIconFill from "../../images/Settings.svg";
import calenderIconFill from "../../images/Calendar.svg";
import pathologyPlanIconFill from "../../images/sidebar/i4.svg";
import dataaExportIconFill from "../../images/sidebar/i6.svg";
import AkunahIconFill from "../../images/analyse1_white.svg";
import notification from "../../images/Bellicon.svg";
import useAxios from "../../axiosinstance";
import med from "../../images/med.svg";
import Settings from "../settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import { accessFeatures } from "../../redux/actions";
import { s3UrlLink } from "../../utilities/S3Url";
const LeftSidebar = (props) => {
  const translation = useTranslation();
  // const { keycloak, initialized } = useKeycloak();
  //assigning location variable
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const freatures = useSelector((state) => state.mainReducer.featuresData.arr);
  let notification_data= useSelector((state) => state.mainReducer.notificationRef.arr);
  const axiosInstance = useAxios();
  const location = useLocation();
  const dispatch = useDispatch();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  // const appLogout = () => {
  //   UserService.doLogout()
  //   localStorage.setItem('local_auth', JSON.stringify(false));
  //   localStorage.removeItem("local_token");

  // };

  const [show, setShow] = useState(false);
  const reff = useSelector((state) => state.mainReducer.noteRef);
  // const [items, setItems] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataCount, setDataCount] = useState("");
  const [countConsent, setCountConsent] = useState("")
  const [notificationData, setNotificationData] = useState(
    sessionStorage.getItem("notificationData")
  );
  // const getCount = async () => {
  //   try {
  //     const result = await axiosInstance.current.get(
  //       `extapp/doctors/getCountOfViewZero?akdc_id=${login.ing_ak_id}`

  //     );

  //     if (result) {
  //       console.log(result.data.data_count, "reffralcount");
  //       setDataCount(result.data.data_count)
  //     }

  //   } catch (err) {
  //     console.log(err.message || "Unexpected Error!");
  //   } finally {
  //     console.log("api response finally");
  //   }
  // }
  // useEffect(() => {
  //   if (reff) {
  //     getCount()
  //   }
  // }, [reff])

  // menu lock
  useEffect(() => {
    const getData = async () => {
      try{
        
        const response = await axiosInstance.current.get(
          `extapp/doctors/doctorFeature?ak_id=${login.ing_ak_id}`
        );
        if (response.status === 200) {
          // setItems(response.data.features_data);
          dispatch(accessFeatures(response.data.features_data));
        }
      }catch(err){

      }
    };
    getData();
  }, []);


  const getConsentCount = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/datacount?ak_id=${login.ing_ak_id}&status=0`

      );

      if (result) {

        setCountConsent(result.data.total)
      }

    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }

  }

  const handleNavigateMed = ()=>{
    window.open(`https://med.akunah.com?ak_id=${window.btoa(login.ing_ak_id)}`)
  }

  useEffect(() => {
    if (reff) {
      getConsentCount()
    }

  }, [reff])



  return (
    <>
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse} id="sidebar_main">
        <SidebarHeader>
          <br />
          <div
            className="closemenu"
            onClick={() => {
              menuIconClick();
              props.onClick();
            }}
          >
            {/* changing menu collapse icon on click */}
            {<img src={arrowCollapse} className="left-logo" alt="logo" />}
          </div>
        </SidebarHeader>
        <SidebarContent className="left_sidebar_body">
          <Menu iconShape="square">
            <MenuItem
              className={splitLocation[1] === "" ? "active" : ""}
              icon={
                <img src={homeIconFill} alt="menu-icon" className="nav_icon" />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.home}
            >
              {translation.home}
              <Link to="/" />
            </MenuItem>
            <MenuItem
              className={splitLocation[1] === "schedular" ? "active" : ""}
              icon={
                <img
                  src={calenderIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.calender}
            >
              {translation.calender}
              <Link to="/schedular" />
            </MenuItem>
            <MenuItem
              className={
                splitLocation[1] === "patient-list" ||
                  splitLocation[1] === "patient-view" ||
                  splitLocation[1] === "patient-details" ||
                  splitLocation[1] === "management-plan" ||
                  splitLocation[1] === "view-management-plan" ||
                  splitLocation[1] === "doctor-score-list" ||
                  splitLocation[1] === "patient-demographic" ||
                  splitLocation[1] === "akunah-connect" ||
                  splitLocation[1] === "notes" ||
                  splitLocation[1] === "patient-operative-note-list" ||
                  splitLocation[1] === "document-list" ||
                  splitLocation[1] === "add-document" ||
                  splitLocation[1] === "new-document" ||
                  splitLocation[1] === "pdf-generate" ||
                  splitLocation[1] === "insurance"

                  ? "active"
                  : ""
              }
              icon={
                <img
                  src={patientsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.patients}
            >
              {translation.patients}
              <Link to="/patient-list" />
            </MenuItem>

            <MenuItem
              className={
                splitLocation[1] === "operative-notes-list" ||
                  splitLocation[1] === "add-operative-notes" ||
                  splitLocation[1] === "post-operative-note" ||
                  splitLocation[1] === "view-genaral-operative-notes" ||
                  splitLocation[1] === "view-operative-notes"
                  ? "active"
                  : ""
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.OperativeNotes}
              icon={
                <img
                  src={mgmtPlanIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.OperativeNotes}
              <Link to="/operative-notes-list" />
            </MenuItem>

            {/* <MenuItem
              className={splitLocation[1] === "cases-list" ? "active" : ""}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.REFLECTComplex}
              icon={
                <img
                  src={dataExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.REFLECTComplex}
          
              <a
                href={process.env.REACT_APP_REFLECT_URL}
                target="_blank"
                rel="noreferrer"
              ></a>
            </MenuItem> */}
            {freatures.Radiology ? (
              <MenuItem
                className={splitLocation[1] === "radiology" ? "active" : ""}
                icon={
                  <img
                    src={opNotesIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.Radiology}
              >
                {translation.Radiology}
                {/* <a
                href={"https://pacs.akunah.com/sign-in?ReturnUrl=%2f"}
                target="_blank"
                rel="noreferrer"
              ></a> */}
                <Link to="/radiology" />
              </MenuItem>
            ) : (
              ""
            )}
            {freatures.Pathology ?
              <MenuItem
                className={splitLocation[1] === "pathology" ? "active" : ""}
                icon={
                  <img
                    src={pathologyPlanIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.Pathology}
              >
                {translation.Pathology}
                <Link to="/pathology" />
              </MenuItem>
              : ""}
            <MenuItem
              className={splitLocation[1] === "referrals" ? "active" : ""}
              icon={
                <img
                  src={document}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Referrals"}
            >
              Referrals
              <span className="position-absolute translate-middle badge rounded-pill bg-danger" style={{ left: "70%" }}>
                {/* {dataCount === 0 ? "" : dataCount} */}
              </span>
              <Link to="/referrals" />
            </MenuItem>

            {freatures.Pacs ?
              <MenuItem
                className={splitLocation[1] === "akmed" ? "active" : ""}
                icon={
                  <img
                    src={opNotesIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"PACS"}
              >
                {"PACS"}

                <a
                  href={"https://pacs.akunah.com/sign-in?ReturnUrl=%2F"}
                  target="_blank"
                  rel="noreferrer"
                  alt=""
                ></a>
              </MenuItem>
              : (
                ""
              )}

            {/* https://pacs.akunah.com/sign-in?ReturnUrl=%2F */}
            {freatures.Akunah_Med ? (
              <MenuItem
                className={splitLocation[1] === "akmed" ? "active" : ""}
                icon={<img src={med} alt="menu-icon" className="nav_icon" />}
                data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Akunah Med"}
              >
              
                {translation.akmed}

                

                <a
                  onClick={handleNavigateMed}
                  target="_blank"
                  rel="noreferrer"
                ></a>



              </MenuItem>
            ) : (
              ""
            )}

            <MenuItem
              className={splitLocation[1] === "my-consents" ? "active" : ""}
              icon={<img src={frame} alt="menu-icon" className="nav_icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Consents"
              />}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Consents"}
            >
              Consents


              {menuCollapse ? <>
                {countConsent !== 0 && (
                  <span className="count_icon_1">
                  </span>
                )}
              </> : (<>
                {countConsent && countConsent !== 0 ? (
                  <span className="count_icon">
                    {countConsent === 0 ? "" : countConsent}
                  </span>
                ) : null}
              </>)}

              <Link to="/my-consents" />
            </MenuItem>

            <MenuItem
              className={splitLocation[1] === "All-Notifications" ? "active" : ""}
              icon={<img src={notification} alt="menu-icon" className="nav_icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Notifications"
              />}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Notifications"}
            >
              Notifications


              {/* {menuCollapse ? <>
                {notificationData !== 0 && (
                  <span className="count_icon_1">
                  </span>
                )}
              </> : (<>
                {notificationData !== 0 && (
                  <span className="count_icon">
                    {notificationData === 0 ? "" : notificationData}
                  </span>
                )}
              </>)} */}
               {menuCollapse ? (
                  notification_data == 0 ? (
                    <span></span>
                  ) : (
                    <span className="count_icon_1"></span>
                  )
                ) : (
                  notification_data && notification_data>0? (
                    <span className="count_icon">
                      {notification_data}
                    </span>
                  ) : null
                )}

              <Link to="All-Notifications" />
            </MenuItem>

            <MenuItem
              className={splitLocation[1] === "my-document" ? "active" : ""}
              icon={<img src={document} alt="menu-icon" className="nav_icon" />}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.MyDocument}
            >
              {translation.MyDocument}
              <Link to="/my-document" />
            </MenuItem>
            {/* <MenuItem
              className={splitLocation[1] === "data-export" ? "active" : ""}
              icon={
                <img
                  src={dataaExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.DataExport}
            >
              {translation.DataExport}
              <Link to="/data-export" />
            </MenuItem> */}

            {/* <MenuItem
              className={
                splitLocation[1] === "data-export-advance" ? "active" : ""
              }
              icon={
                <img
                  src={dataaExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Advance Data Export"
            >
              Advance Data Export
              <Link to="/data-export-advance" />
            </MenuItem> */}
            {freatures.Report ? (
            <MenuItem
              className={
                splitLocation[1] === "reports" ? "active" : ""
              }
              icon={
                <img
                  src={dataaExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Reports"
            >
              Reports
              <Link to="/reports" />
            </MenuItem>
 ) : (
  ""
)}

            <MenuItem
              className={splitLocation[1] === "my-settings" ? "active" : ""}
              // onClick={handleShow}
              icon={
                <img
                  src={settingsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Settings"}
            >
              {translation.settings}
              <Link to="/my-settings" />

            </MenuItem>

            <MenuItem
              className={splitLocation[1] === "akmed" ? "active" : ""}
              icon={<img src={orgIcon} alt="menu-icon" className="nav_icon" />}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Organization"}
            >
              Organization
              <a
                href={"https://org.akunah.com/"}
                target="_blank"
                rel="noreferrer"
              ></a>
            </MenuItem>

            {freatures.Insight ? (
            <MenuItem
              className={splitLocation[1] === "akunah-analyse" ? "active" : ""}
              // onClick={handleShow}
              icon={
                <img
                  src={AkunahIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Insight"
            >
              Insight
              <Link to="insight" />

            </MenuItem>
           ) : (
            ""
          )}

            
            {/* <MenuItem
              className={splitLocation[1] === "template" ? "active" : ""}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.document_templates}
              icon={<img src={form} alt="menu-icon" className="nav_icon" />}
            >
              {translation.document_templates}
              <Link to={`/template/${login.ing_ak_id}`} />
            </MenuItem> */}
          </Menu>
          <SidebarFooter>
            {/* <span className="user_letter">
            <img
              src={homeIconFill}
              alt="icon"
              className=""
            />
          </span> */} 
            <span className="user_letter">
              {login.ing_image === "" ? (
                login.ing_firstname.slice(0, 1) + login.ing_lastname.slice(0, 1)
              ) : (
                <img src={s3UrlLink(login.ing_image)} alt={login.ing_firstname} />
              )}
            </span>
            <div className="username-info">
              <h5>{login.ing_fullname}</h5>
              <span>{login.ing_email}</span>
            </div>
            <p className="app_ver_txt_menu_responsive">
              {translation.AppVersion}: {process.env.REACT_APP_VERSION}
            </p>
          </SidebarFooter>
        </SidebarContent>
      </ProSidebar>

      {/* <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // keyboard={false}
        dialogClassName="modal-xl modal-dialog-centered"
        className="settings_ppup_main"
      >
        <Modal.Header closeButton>
          <Modal.Title>{translation.settings}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="settings_ppup_bdy">
          <Settings handleClose={handleClose} />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default LeftSidebar;