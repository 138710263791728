import React, { useState, useEffect ,useMemo} from 'react'
import { Modal } from "react-bootstrap";
import Checkbox from "../../custom/checkbox/Checkbox";
import useAxios from "../../../axiosinstance"
import AddModelTableSchedular from './AddModelTableSchedular';
import useTranslation from "../../customHooks/translations";
import Button from '../../custom/button/Button';
import Iicon from './Iicon.png'
import { useNavigate } from "react-router-dom";
// import SubmitModel from "../SubmissionModel/SubmitModel"
import { useParams } from "react-router-dom";
import { noteRefresh } from "../../../redux/actions";
import Message from '../../custom/toster/Message';
// import "./Promss.css"
import { useSelector, useDispatch } from 'react-redux';
import { yearDate } from '../../../utilities/DateFormate';



export default function Promsschudle({getRefreshData, openModal, setDisplayConfirmationModal, type, rowId, patient_akid, org_id, allview, form_status,  }) {

  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { rId } = useParams();
  const patientDetails = useSelector((state) => state.mainReducer.patientDetails.arr)
  const dispatch = useDispatch();
  // let org_id = sessionStorage.getItem("orgid")
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const axiosInstance = useAxios();
  const [showModal, setshowModal] = useState(false);
  const [assignErr, setassignErr] = useState(false)
  const [checkboxValues, setCheckboxValues] = useState("");
  const [schedulerForm2, setschedulerForm2] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [userage, setAge] = useState("")
  const [status, setStatus] = useState("")
  const [showModalImp, setShowModalImp] = useState(false)
  const [orgId,setOrgId] = useState("")
  const[activeData, setActiveData]=useState([])
  const [docValue,setDocValue]=useState("")
  const [docErr,setDocErr]=useState("")
  const [isLoading,setIsLoading]=useState(false)
  const [show, setShow] = useState(false);
  const [displayConfirmationModal2, setDisplayConfirmationModal2] =
    useState(false);
  const [err, setErr] = useState(false)

  useEffect(() => {
    setshowModal(openModal)
  }, [openModal])
  const handleClose = () => {
    setshowModal(false);
    setDisplayConfirmationModal(false)
    setCheckboxValues("")
setDocValue("")
setErr("")
  };

  useEffect(() => {
    setAge(yearDate(patientDetails.dob))
  }, [patientDetails])
  let ak = patient_akid?.search("AKPT")
  const GetNoteDetails2 = () => {
    axiosInstance.current
      .get(
        `extapp/doctors/getPatientProfile?ak_id=${patient_akid}&ak_dc=${login.ing_ak_id}`
      )
      .then((response) => {
        setName(response.data.data.firstname + (" ") + response.data.data.lastname);
        // setAge(Number(yearDate(response.data.data.dob)));
      })
      .catch((error) => {
        if (error.response.status === 401) {
        }
      });
  };

  useEffect(() => {
    if (ak === 0 && openModal) {
      GetNoteDetails2()
    }
  }, [ak, openModal])
  let jsonHip = useSelector((state) => state.mainReducer.hipScoreReducer);
  let adultKneeScore = useSelector((state) => state.mainReducer.adultKneeScore);
  let footAnkle = useSelector((state) => state.mainReducer.footAnkle);
  let pediaKnee = useSelector((state) => state.mainReducer.pediaKnee);
  let elbowScore = useSelector((state) => state.mainReducer.elbowScore);
  let handWrist = useSelector((state) => state.mainReducer.handWrist);
  let shoulderJson = useSelector((state) => state.mainReducer.shoulderScore);
  let generalJson = useSelector((state) => state.mainReducer.GeneralFormReduces);

  const link_forms = async () => {
    try {
      const response = await axiosInstance.current.post(
        `extapp/doctors/link_forms?ak_dc=${login.ing_ak_id}&ak_pt=${patient_akid}&pat_form_id=${checkboxValues}&doc_form_id=${rowId}&ak_ou=${org_id?org_id:docValue}`
      );
      if (response.status === 201) {
        setMessage("PROMs and Clinical Exam successfully linked");
        setMessageType("success");
        setTimeout(() => {
          setMessage(false)
          navigate(`/patient-details/${window.btoa(patient_akid)}?form_type=${type}`);
        }, 3000);
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setshowModal(false);
        setDisplayConfirmationModal2(true)
        setDisplayConfirmationModal(false)
        // setShowModalImp(false)
      }
    } catch (error) {
    } finally {
    }
  }



  const updateCheckboxValues = (newValue) => {
    if (newValue.checked) {
      setCheckboxValues(newValue.resource_id);
      setOrgId(newValue.org_Id)
    }
  };


  let kneejson = Number(userage) >= 15 ? adultKneeScore : pediaKnee

  // let kneetype =  Number(userage) >= 15? "5" : "20" ;
  // let alltype = (type === "20" || type === "5") ? kneetype : type
  let alltype = type
  const handleOpenModal = async () => {
    setCheckboxValues("")
    try {
      let res = await axiosInstance.current.post(
        `/extapp/forms/doc_update_form?resource_id=${rowId}&ak_pt=${patient_akid}&ak_dc=${login.ing_ak_id}&ak_ou=${org_id}&type=${alltype}`,
        alltype === "4" ? shoulderJson : alltype === "6" ? jsonHip : alltype === "21" ? footAnkle : alltype === "22" ? elbowScore : alltype === "23" ? handWrist : alltype === "33" ? generalJson : kneejson,
        // `extapp/forms/create_all_types_of_form?ak_id=${login.ing_ak_id}&ak_ou=${org_id}&ak_pt=${patient_akid}&type=${alltype}&resource_id=&otherData=&doctor=true&isDoctor=1&action=1`,alltype==="4" ?shoulderJson:alltype==="6"?jsonHip:alltype==="21"?footAnkle:alltype==="22"?elbowScore:alltype==="23"?handWrist:alltype==="33"?generalJson:kneejson,
        {
          headers: {
            "Content-Type": "application/json",
          },
        })
      if (res) {
        setMessage("New PROMs request sent successfully");
        setMessageType("success");
        setTimeout(() => {
          setMessage(false)
        }, 3000);
        dispatch(noteRefresh(new Date().getMilliseconds()));
        setshowModal(false);
        setDisplayConfirmationModal2(true)
        setDisplayConfirmationModal(false)
        getRefreshData();
      }
    } catch (err) {
      if (err.response.data.status === false) {
        setMessage("PROMs already requested !!!!");
        setMessageType("error");
        setshowModal(false);
        setDisplayConfirmationModal2(true)
        setDisplayConfirmationModal(false)
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      }
    }
  }
  const [err_status, setErrStatus] = useState(0)
  const getData = async () => {
    setshowModal(false)
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/viewDetailsScoringSection/${login.ing_ak_id}/${patient_akid}?org_id=&type_id=${alltype}&status=pending&doctor=1&userid=${login.ing_ak_id}`
      );
      setErrStatus(0)
      if (form_status == 3) {
        setschedulerForm2([])
      } else {
        setschedulerForm2(response.data.data.filter((item) => item.status == 1));
      }
      setStatus(response.data.proms_request_status)
    } catch (error) {
      if (error) {
        setErrStatus(1)
        setStatus(1)
        setschedulerForm2([])
      }
    } finally {
      setshowModal(true)
    }
  };

  useEffect(() => {
    if (patient_akid && type !== "undefined" && type !== "" && type !== undefined) {
      getData();
    }
  }, [patient_akid, type])


  // useEffect(()=>{
  //   if(schedulerForm2?.length===0){
  //     setDisplayConfirmationModal(false)
  //     setDisplayConfirmationModal2(true)
  //   }
  // },[])

const handleCloseImp=()=>{
  setShowModalImp(false)
setDocValue("")
setDocErr("")
}

const activeDoctor= async ()=>{
  try{
    setIsLoading(true)
    const response = await axiosInstance.current.get(
      `extapp/doctors/active_practitioner_org_lists?ak_dc=${login.ing_ak_id}&akou=${orgId}`
    );
    if(response.status === 200){
      setActiveData(response.data.doctor_names);
      setIsLoading(false)
    }
  }catch(err){
    setIsLoading(false)
  }
}

// useEffect(()=>{
//   if(orgId && checkboxValues){

//     activeDoctor()
//   }
// },[orgId, checkboxValues])


const handleCheckboxValue =(checkValue,event)=>{
setDocValue(checkValue)
  }

const handleCreate = () => {
  // if(checkboxValues === ""){
  //   setErr(true)
  //   setShowModalImp(false)
   
  // }else{
  //   if(activeData.length > 1 ){
  //     setShowModalImp(true)
  //   }else{
  //     link_forms()
  //   }
  // }
  if(checkboxValues === ""){
      setErr(true) 
    }else{
      link_forms()
      }
}

const handleConfirm=()=>{
  if(docValue === ""){
    setDocErr(true)
    
  }else{
    link_forms()
  }
}


  return (
    <div>
      {message && <Message message={message} type={messageType} />}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xs patient_notes_popup modal-dialog-centered modal-content1"
      >
        {allview === "true" ? (
          <>
            {schedulerForm2?.length === 0 ? (
              <Modal.Header className="close-buttonM" closeButton>
                <Modal.Title >
                  <div className="text-center">
                    Notification
                  </div>
                  {/* Link PROMs<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." /> */}

                </Modal.Title>
              </Modal.Header>
            ) : (
              <Modal.Header className="close-buttonM" closeButton>
                <Modal.Title >

                  {/* Link PROMs<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px", cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." /> */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      Link PROMs<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px", cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." />
                    </div>
                    <span
                      className="fst-italic fw-light "
                      style={{
                        color: "#3F2783",
                        fontSize: "11px",
                      }}
                    >
                      {"Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns."}
                    </span>
                  </div>
                </Modal.Title>
              </Modal.Header>
            )}
          </>
        ) : (
          <>
            {schedulerForm2?.length !== 0 ? (
              <Modal.Header className="close-buttonM" closeButton>
                <Modal.Title >

                  {/* Link PROMs bh<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." /> */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      Link PROMs<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px", cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." />
                    </div>
                    <span
                      className="fst-italic fw-light "
                      style={{
                        color: "#3F2783",
                        fontSize: "11px",
                      }}
                    >
                      {"Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns."}
                    </span>
                  </div>
                </Modal.Title>
              </Modal.Header>) : (
              <Modal.Header className="close-buttonM">
                <Modal.Title >
                  <div className="text-center">
                    Notification
                  </div>
                </Modal.Title>
              </Modal.Header>
            )}
          </>
        )}

        {allview === "true" ? (
          <>
            <Modal.Body className="">

              <>
                {schedulerForm2?.length !== 0 ? (
                  <div className="whitebox" style={{ backgroundColor: "rgb(209 203 230 / 45%)", border: "rgb(209 203 230 / 45%)" }}>
                    <div className='d-flex justify-content-between mb-2'>
                      <h6 className="text-capitalize" style={{ color: "#3f2783" }}>
                        <strong>{name}</strong>
                      </h6>
                      <div className="float-end ps-5" style={{ width: '80px' }}>
                        <Checkbox
                          selected={"CarpalDiagnosis1"}
                          onChange={"handleCarpalDiagnosis1"}
                          styleClass="custom_check_box tick f_400 large top_18"
                          id="CarpalDiagnosis1"
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span className="" style={{ color: "#3f2783" }}>
                        <strong>{userage} {userage <= 1 ? "Year" : "Years"}</strong></span>
                      <span className="float-end ps-5 text-capitalize" style={{ color: "#3f2783" }}><strong>{patient_akid} </strong>&nbsp;</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {err_status !== 1 && (
                      <div className="text-center">
                        <p><strong>No new PROMs are currently available for linking </strong></p>
                        <p className='mt-3'><strong>Please wait for the patient to complete  the requested PROMs</strong></p>
                        {/* <p></p> */}
                        <label className="c_pointer" style={{ marginTop: "11px" }}>
                          <Button
                            onClick={() => setDisplayConfirmationModal(false)}
                            value="Got it"
                            buttonStyle="btn_outline ms-2 mb-3 "
                            width="154px"
                            height={'50px'}
                          />
                        </label>
                      </div>
                    )}
                  </>
                )}
                {schedulerForm2?.length !== 0 && (
                  <>
                    <div className="whitebox"
                      style={{
                        border: "1px solid #3f2783",
                        // height: "200px",
                        // width: "100%",
                      }}>
                      <label>Please Choose PROMs to link</label>
                      <div className="scrollbar_vert " style={{ height: "140px" }}>
                      
                        <AddModelTableSchedular data={schedulerForm2} updateCheckboxValues={updateCheckboxValues} ak_id={login.ing_ak_id} />
                      </div>
                    </div>
                    {err && (
                      <>
                        {checkboxValues === "" && (
                          <span className='text-danger text-center'>This is field is required</span>
                        )}
                      </>
                    )}

                    <div className="text-center">
                      <br />
                      <Button
                        onClick={handleCreate}
                        value="Submit"
                        buttonStyle="btn_outline ms-2 mb-3 clk_btn_disbled"
                        width="400px"
                        height={'50px'}
                        disabled={isLoading}
                      />
                      <br />
                      {assignErr && (
                        <>
                          {checkboxValues.length === 0 && (
                            <span className="text-danger">Please select atleast one schedule or close.</span>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className="text-center">
                  {/* <p><strong>No new PROMs are currently available for linking </strong></p> */}

                  {status != 3 && (
                    <>
                      {schedulerForm2?.length !== 0 ? (
                        <>
                          <p><strong>Can't find appropriate PROMs for linking?</strong></p>
                          <label onClick={handleOpenModal} className="c_pointer">
                            <u><b>Request New PROMs</b></u>
                          </label>
                        </>

                      ) : (
                        <>
                          <p><strong>No new PROMs are currently available for linking </strong></p>
                          <label className="c_pointer" style={{ marginTop: "20px" }}>
                            <Button
                              onClick={() => handleOpenModal()}
                              value="Request New PROMs"
                              buttonStyle="btn_outline ms-2 mb-3 "
                              width="206px"
                              height={'50px'}
                            />
                          </label>
                        </>
                      )}
                    </>
                  )}
                  {/* <label onClick={handleOpenModal} className="c_pointer">
                  <u><b>Request New PROMs</b></u>
                </label> */}
                </div>
              </>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Body className="">
              {schedulerForm2?.length !== 0 ? (
                <>
                  <div className="whitebox" style={{ backgroundColor: "rgb(209 203 230 / 45%)", border: "rgb(209 203 230 / 45%)" }}>
                    <div className='d-flex justify-content-between mb-2'>
                      <h6 className="text-capitalize" style={{ color: "#3f2783" }}>
                        <strong>{name}</strong>
                      </h6>

                      <div className="float-end ps-5" style={{ width: '80px' }}>
                        <Checkbox
                          selected={"CarpalDiagnosis1"}
                          onChange={"handleCarpalDiagnosis1"}
                          styleClass="custom_check_box tick f_400 large top_18"
                          id="CarpalDiagnosis1"
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span className="" style={{ color: "#3f2783" }}>
                        <strong>{userage} {userage <= 1 ? "Year" : "Years"}</strong></span>
                      <span className="float-end ps-5 text-capitalize" style={{ color: "#3f2783" }}><strong>{patient_akid} </strong>&nbsp;</span>
                    </div>
                  </div>
                  {schedulerForm2?.length !== 0 && (
                    <>
                      <div className="whitebox"
                        style={{
                          border: "1px solid #3f2783",
                          // height: "200px",
                          // width: "100%",
                        }}>
                        <label>Please Choose PROMs to link</label>
                        <div className="scrollbar_vert " style={{ height: "140px" }}>
                          <AddModelTableSchedular data={schedulerForm2} updateCheckboxValues={updateCheckboxValues} ak_id={login.ing_ak_id} />

                        </div>
                      </div>
                      {err && (
                        <>
                          {checkboxValues === "" && (
                            <span className='text-danger text-center'>This is field is required</span>
                          )}
                        </>
                      )}
                      <div className="text-center">
                        <br />
                        <Button
                          onClick={handleCreate}
                          value="Submit"
                          buttonStyle="btn_outline ms-2 mb-3 "
                          width="400px"
                          height={'50px'}
                        />
                        <br />
                        {assignErr && (
                          <>
                            {checkboxValues.length === 0 && (
                              <span className="text-danger">Please select atleast one schedule or close.</span>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {status != 3 && (
                    <>
                      <div className="text-center">
                        <p>Can't find appropriate PROMs to linking?</p>
                        <label onClick={handleOpenModal} className="c_pointer">
                          <u><b>Request New PROMs</b></u>
                        </label>
                      </div>

                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="text-center">
                    <p><strong>No new PROMs are currently available for linking </strong></p>
                    <p className='mt-3'><strong>Please wait for the patient to complete  the requested PROMs</strong></p>
                    {/* <p></p> */}
                    <label className="c_pointer" style={{ marginTop: "11px" }}>
                      <Button
                        onClick={() => setDisplayConfirmationModal(false)}
                        value="Got it"
                        buttonStyle="btn_outline ms-2 mb-3 "
                        width="154px"
                        height={'50px'}
                      />
                    </label>
                  </div>
                </>
              )}

              
            </Modal.Body>
          </>
        )}
      </Modal>
{/* {Array.isArray(activeData) && activeData.length > 1 &&(
<>
<Modal show={showModalImp}
        onHide={handleCloseImp}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xs patient_notes_popup modal-dialog-centered modal-content1">
<Modal.Header className="close-buttonM">
<Modal.Title >
                  <div className="text-center"
                  style={{fontWeight: 700,
                    fontSize:"larger"}}
                    >
                    Important
                  </div>
               
                <div
        style={{
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          Please select to which clinician this linked
        </span>
        <div
          style={{
            marginTop: "2px",
            fontSize: "20px",
            fontWeight: 500,
            marginBottom: "22px"
          }}
        >
          form will be assigned
        </div>
      </div>
    
      {activeData.map((item, index) => {
        const cleanedLabel = item.label.replace(/\s*\(.*?\)/g, "");
        
        return ( 
  <div
    key={item.value} 
    className={`form-group custom_check_box custom_radio_box large text-black`}
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginLeft: "80px",
      
    }}
  >
   {docValue==item.value?
    <input
      type="radio"
      id={`radioOption-${item.value}`}
      name="radio"
      defaultChecked={true}
      checked={true}
    
    />
   :
   <input
   type="radio"
   id={`radioOption-${item.value}`}
   name="radio"
   onClick={()=>{
     handleCheckboxValue(item.value)
   }}
 />
   
   } 
    <label
      htmlFor={`radioOption-${item.value}`}
      onClick={()=>{
        handleCheckboxValue(item.value)
      }}
      style={{
        fontSize: "16px",
       
      }}
    >
      {cleanedLabel} 
    </label>
   
  </div>
)})} 
{docErr && (
                      <>
                        {docValue === "" && (
                          <span className='text-danger text-center' style={{fontSize: "small",
                            marginLeft: "83px"}}>This is field is required</span>
                        )}
                      </>
                    )}


                </Modal.Title>
  </Modal.Header>
  <Modal.Footer className="text-center" style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button  value="Cancel"
                            buttonStyle="btn_outline"
                            width="154px"
                            height={'50px'}
                            onClick={handleCloseImp}>
         
        </Button>
       
        <Button  value="Confirm"
                            buttonStyle="btn_green"
                            width="154px"
                            height={'50px'}
                            onClick={handleConfirm}>
         
        </Button>
      </Modal.Footer>
       
</Modal>
</>
)} */}
    
    </div>
  )
}
