import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import viewIcon from "../../../images/eye-icon.svg";
import useAxios from "../../../axiosinstance";
export default function AddModelTableSchedular({
  data,
  updatePagination,
  doctorid,
  onCheckboxChange,
  updateCheckboxValues,
  ak_id
}) {
  //   const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const { dcid } = useParams();
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  // let doctorId=window.atob(akid)
  let patientId = akid
  //   const [doctorID] = useState(login.ing_ak_id);
  const [searchParams] = useSearchParams();
  let org = searchParams.get("org");

  const [check, setCheck] = useState(false);
 
  // const handleCheckboxClick = () => {
  //   // Perform checkbox logic
  //   // Call the callback passed from the parent
  //   if (onCheckboxChange && typeof onCheckboxChange === 'function') {
  //     setCheck()
  //     onCheckboxChange();
  //   }
  // };
  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   console.log(name, checked,"name, checked");
  //   //
  //   updateCheckboxValues({ name, checked });
  // };
  // const handleCheckboxChange = (resource_id, event) => {
  //   const { name, checked } = event.target;
  //   console.log(name, checked, "name, checked");
  //   // Pass resource_id along with name and checked to the parent component
  //   updateCheckboxValues({ resource_id, name, checked });
  // };/


const[resId,setResId]=useState('')
  const handleCheckboxChange = (resource_id, event, org_Id) => {
    setResId(resource_id)
    const { name, checked } = event.target;
    updateCheckboxValues({ resource_id, name, checked,org_Id });
    // setOrgId(org_Id)
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  };



  const handleRedirect = (rowId, formID,) => {
    if (formID === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "shoulder-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hip-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "foot-ankle-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "elbow-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hand-wrist-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "pediaknee-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "adultknee-view-treatment-form" +
        `/${rowId}?doctor=${ak_id}&patient=${patientId}&management=true&is_doctor_form=true`,
        "_blank"
      );
    }
  };
  console.log(check,'check');
  
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "",
      //   id: "1",
      //   headerClassName: "col-img",
      //   className: "col-img",
      //   accessor: "type",
      //   Cell: (row) => (
      //     <span>
      //       <label className="user-letter small">
      //         {row.value.charAt(0).toUpperCase()}
      //       </label>
      //     </span>
      //   ),
      // },

      {
        Header: "assessment_date",
        accessor: "assessment_date",
        Cell: (row) => (
          <label className="text-black f_500 pt-3"> <span className=""></span>
            {row.row.original.assessment_date}
          </label>
        ),
      },

      {
        Header: "Speciality",
        accessor: "speciality",
        Cell: (row) => (
          <>
          <div  style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              lineHeight: 0.5,
            }}>
            <label className="text-black pt-5" 
            style={{ whiteSpace: "nowrap", wordBreak: "break-word" }}
           
            >
              {row.row.original.type_id === 5
                ? `Knee ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                : row.row.original.type_id === 4
                  ? `Shoulder ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                  : row.row.original.type_id === 6
                    ? `Hip ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                    : row.row.original.type_id === 21
                      ? `Foot and ankle ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                      : row.row.original.type_id === 22
                        ? `Elbow ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                        : row.row.original.type_id === 23
                          ? `Hand and Wrist ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                          : row.row.original.type_id === 33
                            ? `General ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}` :
                            row.row.original.type_id === 20
                              ? `Pediatric knee ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}` :
                              row.row.original.type_id === 24
                                ? `Adolescent knee ${row.row.original.evaluated!==''? `(${row.row.original.evaluated})`:''}`
                                : ""}
            </label>
            {/* <div> */}
              <span style={{ whiteSpace: "normal", wordBreak: "break-word", fontWeight:400, lineHeight:1.2 }} className="text-black">{row.row.original.doctor_name}, {row.row.original.org_name}</span>
            {/* </div> */}
            </div>
          </>
        ),
      },

      {
        Header: "Checkbox",
        accessor: "checkbox",
        Cell: (row) => (
          <>
            {row.row.original.status == 3 ? (
              <>
                <b>
                  <span style={{ color: "orange" }}>PROMs Requested</span></b>
              </>

            ) : (
              <div
                className={`form-group custom_check_box custom_radio_box large${check ? "tick" : ""
                  } text-black`}
                style={{ fontSize: "0px", width:"50px", paddingLeft:"10px" }}
              >
                {console.log(resId==row.row.original.resource_id,"MMDMNDND")
                }
                <input
                  type="radio"
                  value={resId}
                  name="radio"
                  id={row.row.original.resource_id}
                  defaultChecked={resId==row.row.original.resource_id}
                  checked={resId==row.row.original.resource_id||false}
                  onChange={(event) =>
                    handleCheckboxChange(row.row.original.resource_id, event, row.row.original.org_id)
                  }
                />
                <label key={row.row.original.resource_id}
                  // className="ms-4"
                  id={row.row.original.resource_id}
                  for={row.row.original.resource_id} > </label>
              </div>
            )}

          </>
        ),
      },
      {
        Header: "view",
        accessor: "view",
        Cell: (row) => (
          <>
            <div
              data-bs-toggle="tooltip" data-bs-placement="top" title="View Form"
              className="me-6"
              style={{marginBottom: "20px",marginTop: "15px",
                marginRight: "20px"}}
              onClick={() => handleRedirect(row.row.original.resource_id, row.row.original.type_id)}
            >
              <img
                className={`action_icons`}
                src={viewIcon}
                alt="Icon"
              />
            </div>
          </>
        )
      }
    ],
    [resId]
  );
  // console.log(resource_id, "checkcheck");
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div className=" " id="ripScrollableDiv">
      {/* <InfiniteScroll
        dataLength={rows.length}
        next={updatePagination}
        hasMore={true}
        scrollableTarget="ripScrollableDiv"
        // loader={<TableSpinner />}
      > */}
        <hr className='hr_margin' style={{marginTop: "0px"}}></hr>
      <table {...getTableProps()} className="custom-tbl patient_clinic_form">
        <tbody {...getTableBodyProps()}>
        <tr>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{border:'hidden', marginBottom:"8px"}}>
                <td>
                  <span className="circle_"></span>
                </td>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
          </tr>
        </tbody>
      </table>
      {/* </InfiniteScroll> */}
    </div>
  );
}