import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import InputField from "../../custom/inputfield/InputField";
import useAxios from "../../../axiosinstance";

import { useSelector, useDispatch } from "react-redux";
import useTranslation from "../../customHooks/translations";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Dropdown from "../../custom/dropdown/Dropdown";
import PatientListForModel from "./PatientListForModel";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import { getDateFormat } from "../../../utilities/DateFormate"
import Button from "../../custom/button/Button";
import Message from "../../custom/toster/Message";
import { noteRefresh } from '../../../redux/actions';
import { useSearchParams } from "react-router-dom";
import SortDropdown from "../../custom/sortdropdown/SortDropdown";
import InputMask from "react-input-mask";
const PatientModel = ({ modelOpen, setShowModal, type, orgid, allPatients, setOrgid, rule_id }) => {
  const axiosInstance = useAxios();
  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [firstName, setFirstName] = useState("");
  const dispatch = useDispatch()
  const [lastName, setLastName] = useState("");
  const [patientdata, setData] = useState([]);
  const [call, setCall] = useState(0);

  const [sortType, setSortType] = useState(login.sortType);
  // const [email] = useState("");
  // const [startDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [startDate, setStartDate] = useState(new Date());
  const [lastTypingTime, setLastTypingTime] = useState(null);


  const [mobile, setmobile] = useState("");
  // const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");

  const [startDate, setStartDate] = useState("");

  const [offset, setOffset] = useState(10);
  const [limit] = useState(10);
  // const [sortType] = useState("last_created");
  const [org, selectOrg] = useState(orgid);

  const [, setTotaldata] = useState("");

  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchEnable, setsearchEnable] = useState("");
  let searchType = searchParams.get("search");
  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };
  let dateFormat = login.ing_date_formate;

  const advsearchToFirstName = (event) => {
    setLastTypingTime(new Date().getTime());
    setFirstName(event);
    removeQueryParams();
    if (event === "") {

    } else {
      setsearchEnable("N");
    }
    setOffset(50);
  };
  const advsearchTolastName = (e) => {
    setLastTypingTime(new Date().getTime());
    setLastName(e);
    removeQueryParams();
    if (e === "") {

    } else {
      setsearchEnable("N");
    }
    setOffset(50);


  };
  const handleDob = (event) => {
    setLastTypingTime(new Date().getTime());
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(50);
    setStartDate(event);
  };

  const advsearchTomobile = (e) => {

    setLastTypingTime(new Date().getTime());
    setmobile(e);
    if (e === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(50);
  };
  const advsearchToMail = (event) => {
    setLastTypingTime(new Date().getTime());
    setEmail(event);
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(50);
  };


  const [dataload, setDataload] = useState(true)
  const [Patientarray, setPatientarray] = useState([])
  const[Select_All_Patient,setSelect_All_Patient]=useState(false)
  const handleOrg = (e) => {
    setLastTypingTime(new Date().getTime());
    selectOrg(e);
  };

  const handleSort = (e) => {
    setCall(1);
    removeQueryParams();
    setSortType(e);
  };

  const handleClose = () => {
    setFirstName("")
    setLastName("")
    setShowModal(false);
    setOrgid("")
    setSelect_All_Patient(false)
    setPatientarray([])
  };
  const [Reff, setReff] = useState('')
  useEffect(() => {
    if (typeof searchType === "object") {
      setsearchEnable("N");
    }
  }, [searchType]);
  useEffect(() => {
    if (lastTypingTime) {
    
      const timer = setTimeout(() => {
        const getData = async () => {
          const dob = startDate ? startDate : "";
          try {
            const response = await axiosInstance.current.get(
              `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=0&f_name=${firstName}&l_name=${lastName}&email=${email}&mobile=${mobile}&dob=${dob}&sortType=${sortType}`
            );
            // setDataload(true)
            setData(response.data.data);
            setTotaldata(response.data.total);

          } catch (error) {
          } finally {
            setDataload(false)
            setIsLoading(false);
          }
        };
        getData();
      }, 2000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [firstName, lastName, mobile, email, startDate]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  console.log(orgid, "orgidorgid")
  useEffect(() => {

    const getData = async () => {
      try {
        setDataload(true)
        const response = await axiosInstance.current.get(
          `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=0&f_name=${firstName}&l_name=${lastName}&email=${email}&mobile=${mobile}&dob=${startDate}&sortType=${sortType}`
        );

        setData(response.data.data);
        setTotaldata(response.data.total);
      } catch (error) {
      } finally {
        setDataload(false)
      }
    };
    if (orgid && modelOpen) {
      getData();
    }

  }, [orgid, modelOpen,sortType]);

  const handleReset = (e) => {
    setFirstName("");
    setLastName("");
    setmobile("");
    setStartDate("");
    setEmail("");
    setSortType(login.sortType);

  };

  const addMoreData = async () => {
   
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=${offset}&f_name=${firstName}&l_name=${lastName}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(patientdata.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };
  useEffect(() => {
    setOffset(10);
    selectOrg("");
    setData([])
    // setDataload(true)
  }, [modelOpen]);

  
  let json = JSON.stringify({
    patient_ids: Select_All_Patient?[]:Patientarray
  })
  const Adddata = async () => {
    try {
      const response = await axiosInstance.current.put(`/extapp/doctors/updatePatient?rule_id=${rule_id}&ak_id=${login.ing_ak_id}&select_all=${Select_All_Patient?1:''}&org_id=${orgid}`, json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        })
      if (response) {
        dispatch(noteRefresh(new Date().getMilliseconds()));
        // setReff(new Date().getMilliseconds())
        if(allPatients.length<Patientarray.length){
          setMessageType("success");
          setMessage("Patient added successfully");
        }
        if(allPatients.length>Patientarray.length){
          setMessageType("success");
          setMessage("Patient has been removed");
        }

      }
      setTimeout(() => {
        setOrgid("")
        setMessage(false);
        setShowModal(false)
        setReff("")
      }, 3000);
    } catch (err) {

    }
  }
  const CustomInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <InputMask
      mask={login.ing_date_formate==='3'?"9999/99/99":"99/99/9999"} 
      placeholder={login.ing_date_formate === "1" ? "DD/MM/YYYY" : login.ing_date_formate === "2" ? "MM/DD/YYYY" : "YYYY/MM/DD"}
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      className="form-control mb_0"
    />
  ));

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Modal
          show={modelOpen}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg patient_notes_popup modal-dialog-centered"
        >
          <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ marginLeft: "15%" }}>Patient List</Modal.Title>
              <Button
                onClick={() => Adddata()}
                value="Save"
                buttonStyle="btn_fill mr_20 width-5"
              />
            {/* {!Patientarray.length > 0 &&!Select_All_Patient ? (<>   <Modal.Title className="text-center">Patient List</Modal.Title>
            </>):''}

            {Patientarray.length > 0 ||Select_All_Patient? (<>
              <Modal.Title className="text-center" style={{ marginLeft: "15%" }}>Patient List</Modal.Title>
              <Button
                onClick={() => Adddata()}
                value="Save"
                buttonStyle="btn_fill mr_20 width-5"
              />
            </>
            ):''} */}
          </Modal.Header>
          <Modal.Body className="">
            {/* <div className="row">
          <div className="col-6 ">
                    <InputField
                      label="First Name1"
                      value={firstName}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      onChange={advsearchToFirstName}
                    />
                    </div>
                    <div className="col-6 ">
                    <InputField
                      label="Last Name"
                      value={lastName}
                      type="text"
                      placeholder="Last Name"
                      onChange={advsearchTolastName}
                    />
                  </div>
                  </div> */}

            <div className="panel">
              {/* {totaldata > 0 ? ( */}
              <>
                <div className="adv_search_sec">
                  <h4 className="mb_30">{translation.advancedSearch}</h4>
                  {/* <form onSubmit={handleSubmit}> */}

                  <div className="row">
                    <div className="col-sm-6 col-md-4">

                      <InputField
                        label="First Name"
                        value={firstName}
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        onChange={advsearchToFirstName}
                      />
                      <InputField
                        label="Last Name"
                        value={lastName}
                        type="text"
                        placeholder="Last Name"
                        onChange={advsearchTolastName}
                      // formGroupClass="mt-5"

                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Form.Group className="" controlId="fromDate">
                        <Form.Label className="mb_13">
                          {translation.dob}
                        </Form.Label>
                        <DatePicker
                          className="form-control mb_0"
                          dateFormat={getDateFormat(dateFormat)}
                          placeholderText={login.ing_date_formate === "1" ? "DD/MM/YYYY" : login.ing_date_formate === "2" ? "MM/DD/YYYY" : "YYYY/MM/DD"}
                          selected={startDate}
                          onChange={(date) => handleDob(date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={<CustomInput />}
                        />
                      </Form.Group>

                      <InputField
                        label="Phone number"
                        value={mobile}
                        type="text"
                        placeholder="Phone number"
                        onChange={advsearchTomobile}
                        formGroupClass="mt-3"
                        autoComplete="off"
                      // autoComplete="new-password"
                      />

                    </div>

                    <div className="col-sm-6 col-md-4">
                      <InputField
                        label="Email"
                        value={email}
                        type="text"
                        placeholder="Email Address"
                        onChange={(e) => advsearchToMail(e)}
                      />



                      <Button
                        onClick={handleReset}
                        value="Reset"
                        buttonStyle="btn_fill_setting"

                      />
                  <SortDropdown onChange={handleSort} alp={true} />

                    </div>
                  </div>

                </div>
                <hr />


              </>


            </div>

            <div>

              <div>
                {dataload ? <div className="no_data">
                  <div class="spinner-border " role="status" style={{ color: "#3f2783" }}>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                  : (
                    <>
                      {dataload ? <div className="no_data">
                        <div class="spinner-border " role="status" style={{ color: "#3f2783" }}>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div> : (
                        <>
                          {patientdata?.length > 0 ? (
                            <PatientListForModel
                              patientdata={patientdata}
                              updatePagination={fetchMoreData}
                              orgId={org}
                              allPatients={allPatients}
                              setPatientarray={setPatientarray}
                              setSelect_All_Patient={setSelect_All_Patient}
                            />
                          ) : (
                            <div className="no_data">{translation.No_data_found}</div>
                          )}</>
                      )}
                    </>
                  )}



              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PatientModel;
