import React, { useState, useEffect } from "react";
import settingsStyle from "../Settings.module.css";
import { Tabs, Tab } from "react-bootstrap";
import PromsDataTable from "./PromsDataTable";
import Button from "../../custom/button/Button";
import useAxios from "../../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import PromesSchedule from "../../patients/PromesSchedule";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import { noteRefresh } from "../../../redux/actions";

export default function PromsSchedular({ open, Dataget, setTab }) {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [schedulerForm, setschedulerForm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(100);
  const [speciality, setSpeciality] = useState({});
  const [specialityss, setSpecialityss] = useState("33");
  const [activeTab, setActiveTab] = useState("");
  const dispatch = useDispatch();

  const [Reffess, setReffess] = useState("");
  const [organisation, setOrganisation] = useState([]);

  const handlereff = (e) => {
    setReffess(e);
  };
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${
            login.ing_ak_id
          }&limit=${limit}&offset=${0}`
        );
        setSpeciality(response.data.obj);      
        
      } catch (error) {
        setSpeciality({});
      } finally {
        setTab(true);
        setIsLoading(false);
      }
    };
    getData();
  }, [dataRefe]);


  useEffect(()=>{
    const trueKeys = Object.keys(speciality).filter(
      (key) => speciality[key] === true
    );
   if(schedulerForm.length === 0 ){

     if (trueKeys[0] === "shoulder") {
       setActiveTab("allcases");
       setSpecialityss("4");
     } else if (trueKeys[0] === "hip") {
       setActiveTab("hip");
       setSpecialityss("6");
     } else if (trueKeys[0] === "foot") {
       setActiveTab("foot");
       setSpecialityss("21");
     } else if (trueKeys[0] === "elbow") {
       setActiveTab("elbow");
       setSpecialityss("22");
     } else if (trueKeys[0] === "knee") {
       setActiveTab("knees");
       setSpecialityss("5");
     } else if (trueKeys[0] === "handAndWrist") {
       setActiveTab("hand");
       setSpecialityss("23");
     } else if (trueKeys[0] === "general") {
       setActiveTab("general");
       setSpecialityss("33");
     }
   }
  },[ schedulerForm])
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${
            login.ing_ak_id
          }&limit=${limit}&offset=${0}&speciality=${specialityss}`
        );

        Dataget(response.data.data);
        setschedulerForm(response.data.data);
        setOrganisation(response.data.organisation);
      } catch (error) {
        setschedulerForm([])
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [specialityss, dataRefe]);

  function getFirstTruePart(bodyParts) {
    for (const part in bodyParts) {
      if (bodyParts[part] === true) {
        return part;
      }
    }
    return null;
  }

  const firstTruePart = getFirstTruePart(speciality);

  useEffect(() => {
    if (firstTruePart === "shoulder") {
      setActiveTab("allcases");
      setSpecialityss("4");
    } else if (firstTruePart === "hip") {
      setActiveTab("hip");
      setSpecialityss("6");
    } else if (firstTruePart === "foot") {
      setActiveTab("foot");
      setSpecialityss("21");
    } else if (firstTruePart === "elbow") {
      setActiveTab("elbow");
      setSpecialityss("22");
    } else if (firstTruePart === "knee") {
      setActiveTab("knees");
      setSpecialityss("5");
    } else if (firstTruePart === "handAndWrist") {
      setActiveTab("hand");
      setSpecialityss("23");
    } else if (firstTruePart === "general") {
      setActiveTab("general");
      setSpecialityss("33");
    }
  }, [firstTruePart]);
  const addMoreData = async () => {
    setOffset(offset + 100);

    const response = await axiosInstance.current.get(
      `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${login.ing_ak_id}&limit=${limit}&offset=${offset}&speciality=${specialityss}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setschedulerForm(schedulerForm.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };

  const handleTab = (e) => {

    if (e === "allcases") {
      setSpecialityss("4");
      setOffset(100);
    } else if (e === "hip") {
      setSpecialityss("6");
      setOffset(100);
    } else if (e === "foot") {
      setSpecialityss("21");
      setOffset(100);
    } else if (e === "elbow") {
      setSpecialityss("22");
      setOffset(100);
    } else if (e === "knees") {
      setSpecialityss("5");
      setOffset(100);
    } else if (e === "hand") {
      setSpecialityss("23");
      setOffset(100);
    } else if (e === "general") {
      setSpecialityss("33");
      setOffset(100);
    }
    setActiveTab(e);
  };

  return (
    <div>
      <div className="row d-flex flex-wrap">
        <div className="col-md-6 col-sm-2">
          <h4 class={`${settingsStyle.content_main_title}`}>
            {"PROMs Scheduler"}
          </h4>
        </div>

        <div className="col-md-6 col-sm-2 text-end pe-5">
          {/* <Button
            value="+ Add PROMs Schedule"
            buttonStyle="btn_fill mr_15"
            width="auto"
          /> */}
          <PromesSchedule open={open} handlereff={handlereff} />
        </div>
      </div>

      <div className="ms-2 pe-5 mt-4">
        <div className="my_visit_screen org_tab mb_100">
          <Tabs
            id="case-list-tabs"
            activeKey={activeTab}
            // activeKey={speciality.shoulder ? "allcases" : speciality.knee ? "submitted":speciality.handAndWrist? "hand": speciality.elbow ? "elbow":speciality.hip ? "hip":speciality.footAndAnkle ?"foot":speciality.general ?"General" :""}
            onSelect={handleTab}
            className="border-0"
          >
            {speciality.shoulder ? (
              <Tab eventKey="allcases" title={"Shoulder"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "allcases" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            ) : (
              ""
            )}

            {speciality.knee && (
              <Tab eventKey="knees" title={"Knee"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "knees" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}

            {speciality.handAndWrist && (
              <Tab eventKey="hand" title={"Hand and Wrist"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "hand" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}
            {speciality.elbow && (
              <Tab eventKey="elbow" title={"Elbow"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "elbow" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}

            {speciality.hip && (
              <Tab eventKey="hip" title={"Hip"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "hip" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}
            {speciality.footAndAnkle && (
              <Tab eventKey="foot" title={"Foot and Ankle"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "foot" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}
            {speciality.general && (
              <Tab eventKey="general" title={"General"}>
                {isLoading ? (
                  <div style={{ marginTop: "39px", marginLeft: "280px" }}>
                    <div
                      class="spinner-border"
                      role="status"
                      style={{ color: "#3f2783" }}
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === "general" ? (
                      <PromsDataTable
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        organisation={organisation}
                        promsdata={schedulerForm}
                        updatePagination={fetchMoreData}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
